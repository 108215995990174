import 'codemirror/addon/display/placeholder'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/show-hint.css'
import ErrorMixin from '@/mixins/error.mixin'

export default {
  props: {
    text: { type: String, default: '' },
    required: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false }
  },
  mixins: [ErrorMixin],
  data() {
    return {
      tags: [],
      codemirror: null
    }
  },
  computed: {
    value: {
      get() {
        return this.text
      },
      set(v) {
        this.$emit('update:text', v)
      }
    },
    options() {
      return {
        viewportMargin: Infinity,
        mode: 'tag',
        lineWrapping: true,
        placeholder: this.readonly ? '' : 'Memo',
        readOnly: this.readonly
      }
    }
  },
  watch: {
    value(v) {
      if (this.codemirror && v && v !== this.codemirror.getValue()) {
        this.codemirror.setValue(v)
      }
    }
  },
  methods: {
    initCodeMirror() {
      const el = _.get(this, '$refs.label.$refs.input')
      if (!el) {
        setTimeout(() => {
          this.initCodeMirror()
        }, 50)
        return
      } else {
        const cm = this.$CodeMirror.fromTextArea(el, this.options)
        this.codemirror = cm
        if (this.value) {
          cm.setValue(this.value)
        }
        if (!this.readonly) {
          const label = this.$refs.label.$el.querySelector('label')
          cm.on('keypress', () => this.onChange())
          cm.on('cursorActivity', () => this.onChange())
          cm.on('focus', () => {
            label.classList.add('primary--text')
            label.classList.add('v-label--active')
          })
          cm.on('blur', () => {
            label.classList.remove('primary--text')
            if (_.isEmpty(this.value)) {
              label.classList.remove('v-label--active')
            }
          })
        }
      }
    },
    async getTags() {
      if (!this.readonly) {
        const { data, error } = await this.$apollo.query({
          // Query
          query: this.$gql`{
            tags
          }`,
          fetchPolicy: 'no-cache'
        })
        if (!_.isEmpty(error)) {
          // call to string here to prevent 404 page to render
          this.graphQLOnError(_.toString(error))
        }
        const tags = _.get(data, 'tags')
        if (!_.isEmpty(tags)) {
          this.tags = tags.map(t => ({ text: `#${t}`, displayText: `#${t}` }))
        }
      }
    },
    onCmReady(cm) {
      this.codemirror = cm
      if (!this.readonly) {
        cm.on('keypress', () => this.onChange())
        cm.on('cursorActivity', () => this.onChange())
        cm.on('focus', () => this.$refs.label.$el.querySelector('label').classList.add('primary--text'))
        cm.on('blur', () => this.$refs.label.$el.querySelector('label').classList.remove('primary--text'))
      }
    },
    onCmUpdate() {
      if (this.readonly && this.$refs.editor.$el) {
        const tags = this.$refs.editor.$el.querySelectorAll('.cm-tag')
        for (let tag of tags) {
          tag.addEventListener('click', e => {
            this.$emit('tagClicked', e.target.textContent)
          })
        }
      }
    },
    onChange() {
      this.value = this.codemirror.getValue()
      this.$CodeMirror.showHint(
        this.codemirror,
        () => {
          const cursor = this.codemirror.getCursor()
          const token = this.codemirror.getTokenAt(cursor)

          // do not show dropdown if the current token is not a tag, or if it's not a valid symbol
          if (token.type !== 'tag' && token.type !== 'valid-tag-symbol') {
            return
          }
          let list = !_.isEmpty(token.string) ? this.tags.filter(t => t.text.startsWith(token.string)) : []
          // do not show dropdown if the only option is the token
          if (list.length === 1 && list[0].text === token.string) {
            list = []
          }
          return {
            list,
            from: this.$CodeMirror.Pos(cursor.line, token.start),
            to: this.$CodeMirror.Pos(cursor.line, cursor.ch)
          }
        },
        { completeSingle: false }
      )
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.readOnly) {
        this.initCodeMirror()
      }
    })
  },
  created() {
    this.getTags()
  }
}
